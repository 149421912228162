var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "camera-selection-button",
      class: { "camera-selection-button--active": _vm.active }
    },
    [
      _c(
        "ToggleButton",
        {
          staticClass: "w-100",
          attrs: { active: _vm.active, disabled: _vm.disabled },
          on: {
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.camera.name) + " "),
          _c(
            "svg",
            {
              staticClass: "camera-selection-button__chevron-active",
              attrs: {
                height: "18",
                width: "18",
                fill: "currentColor",
                viewBox: "0 0 20 20"
              }
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  d:
                    "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z",
                  "clip-rule": "evenodd"
                }
              })
            ]
          ),
          _c(
            "svg",
            {
              staticClass: "camera-selection-button__chevron-inactive",
              attrs: {
                height: "18",
                width: "18",
                fill: "currentColor",
                viewBox: "0 0 20 20"
              }
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  d:
                    "M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z",
                  "clip-rule": "evenodd"
                }
              })
            ]
          )
        ]
      ),
      _c("div", { staticClass: "small text-center" }, [
        _vm._v(_vm._s(_vm.$t("lang.selected_camera.msg")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }