<template>
  <div class="project-selector">
    <router-link class="text-decoration-none" :to="{ name: 'project-list' }">
      <div class="project-selector__logo">
        <img class="logo" :src="projectLogoUrl" :alt="projectName" />
      </div>
      <div class="project-selector__label">{{$t('lang.return.msg')}}</div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectSelectorButton',
  computed: {
    ...mapGetters('project', ['projectLogoUrl', 'projectName']),
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.project-selector {
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  // z-index: 10;

  @include media-breakpoint-up(md) {
    top: 2rem;
    left: 2rem;
  }

  &__logo {
    background-color: white;
    height: 3.812rem;
    width: 4.437rem;

    @include media-breakpoint-up(md) {
      height: 5.875rem;
      width: 5.875rem;
    }

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }

  &__label {
    background-color: var(--primary);
    color: white;
    text-align: center;
    font-weight: 100;
    font-size: 0.75rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
}
</style>
