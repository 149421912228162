var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-selector" },
    [
      _c(
        "router-link",
        {
          staticClass: "text-decoration-none",
          attrs: { to: { name: "project-list" } }
        },
        [
          _c("div", { staticClass: "project-selector__logo" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: _vm.projectLogoUrl, alt: _vm.projectName }
            })
          ]),
          _c("div", { staticClass: "project-selector__label" }, [
            _vm._v(_vm._s(_vm.$t("lang.return.msg")))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }